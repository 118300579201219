define("gaff-interiors-web/models/image", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    filename: attr("string"),
    url: attr("string"),
    isFeatureImage: attr("boolean", {
      defaultValue: function defaultValue() {
        return false;
      }
    })
  });

  _exports.default = _default;
});