define("gaff-interiors-web/templates/forgot-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ddfzO6v7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-6 h-full\"],[8],[0,\"\\n  \"],[5,\"forgot-password\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/forgot-password.hbs"
    }
  });

  _exports.default = _default;
});