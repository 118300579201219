define("gaff-interiors-web/templates/components/image-text-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1zWhPWnm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-wrap mt-8 lg:px-8 w-full xxl:w-4/5 m-auto\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"row\"],[[28,\"component\",[\"image-text-grid-row\"],null]]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/components/image-text-grid.hbs"
    }
  });

  _exports.default = _default;
});