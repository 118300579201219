define("gaff-interiors-web/services/etw-tailwind-styleguide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({// body
  });

  _exports.default = _default;
});