define("gaff-interiors-web/templates/components/file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bzNxyUHd",
    "block": "{\"symbols\":[\"@text\"],\"statements\":[[4,\"if\",[[24,[\"isTesting\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\",true],[11,\"id\",[29,[[23,0,[\"inputId\"]]]]],[11,\"name\",[29,[[23,0,[\"inputId\"]]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"testModeInputFileSelected\"],null]],[10,\"class\",\"inputfile\"],[10,\"type\",\"button\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\",true],[11,\"id\",[29,[[23,0,[\"inputId\"]]]]],[11,\"name\",[29,[[23,0,[\"inputId\"]]]]],[10,\"accept\",\"image/bmp,image/gif,\\n                image/heic,image/x-icon,image/icns,image/jpeg,image/jp2,\\n                image/pict,image/pjpeg,image/png,image/psd,\\n                application/psd,application/photoshop,image/tiff,image/x-tiff\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"onInputFileSelected\"],null]],[10,\"class\",\"inputfile\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[7,\"label\",true],[11,\"for\",[29,[[23,0,[\"inputId\"]]]]],[10,\"class\",\"font-title hover:bg-grey-lightest bg-grey-lighter text-grey-dark font-bold text-lg rounded flex flex-col justify-center items-center product-image-thumb-w product-image-thumb-h border rounded\"],[8],[1,[23,1,[]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/components/file-input.hbs"
    }
  });

  _exports.default = _default;
});