define("gaff-interiors-web/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute("sign-up"), this.toRoute("session"), this.use("fade", {
      duration: 300
    }), this.reverse("fade"));
    this.transition(this.fromRoute("login"), this.toRoute("session"), this.use("fade", {
      duration: 300
    }), this.reverse("fade"));
    this.transition(this.toRoute("terms-and-conditions"), this.use("fade", {
      duration: 300
    }), this.reverse("fade"));
    this.transition(this.fromRoute("session.seller.products.index"), this.toRoute("session.seller.products.new"), this.use("fade", {
      duration: 300
    }), this.reverse("fade"));
    this.transition(this.fromRoute("session.seller.products.index"), this.toRoute("session.seller.products.product"), this.use("fade", {
      duration: 300
    }));
    this.transition(this.toRoute("session"), this.use("fade", {
      duration: 300
    }), this.reverse("fade"));
    this.transition(this.toRoute("login"), this.use("fade", {
      duration: 300
    }), this.reverse("fade"));
    this.transition(this.toRoute("sign-up"), this.use("fade", {
      duration: 300
    }), this.reverse("fade"));
    this.transition(this.hasClass("contact-form-if"), this.toValue(true), this.use("fade", {
      duration: 300
    }));
    this.transition(this.hasClass('forgot-password'), // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true), this.use('fade', {
      duration: 300
    }), // which means we can also apply a reverse rule for transitions to
    // the false state.
    this.reverse('fade', {
      duration: 300
    }));
  }
});