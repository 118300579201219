define("gaff-interiors-web/models/product", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Please give your item a name",
      disabled: false
    }), (0, _emberCpValidations.validator)("ds-error")],
    description: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Please provide a description"
    }), (0, _emberCpValidations.validator)("length", {
      max: 500
    })],
    price: [(0, _emberCpValidations.validator)("number", {
      allowString: true,
      integer: true,
      gt: 0,
      message: "Price must be specified to the nearest whole € (no cents)"
    }), (0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Please specify a price"
    })],
    condition: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Please specify the product's condition"
    })],
    location: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Please specify a pickup location"
    })],
    images: [(0, _emberCpValidations.validator)("length", {
      min: 3,
      message: "Please add at least 3 photos of your item"
    })],
    categories: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)("length", {
      min: 1,
      message: "Please choose at least 1 category"
    })],
    featureImage: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Please add a feature image for your item"
    })]
  }, {
    dependentKeys: ["status"],
    disabled: Ember.computed("model.status", function () {
      return this.model.status === "DRAFT";
    })
  });

  var _default = Model.extend(Validations, {
    name: attr("string"),
    createdAt: attr("date"),
    updatedAt: attr("date"),
    description: attr("string"),
    price: attr("number"),
    condition: attr("string"),
    location: attr("string"),
    pickupOnly: attr("boolean", {
      defaultValue: false
    }),
    status: attr("string", {
      defaultValue: "DRAFT"
    }),
    featureImage: attr("image"),
    images: attr("image-list"),
    categories: hasMany("product-category"),
    descriptionCharCount: Ember.computed("description", function () {
      return this.description ? this.description.length : 0;
    }),
    descriptionMaxCharsReached: Ember.computed("description", function () {
      return this.description ? this.description.length === 500 : false;
    }),
    lastSaveFailed: Ember.computed("isError", "isValid", function () {
      return this.isError || !this.isValid;
    }),
    formattedCreatedAt: Ember.computed("createdAt", function () {
      return this.createdAt.toLocaleDateString();
    }),
    formatedCondition: Ember.computed("condition", function () {
      return "".concat(this.condition[0]).concat(this.condition.substring(1).toLowerCase());
    }),
    canBeSold: Ember.computed("status", function () {
      return this.status === "LIVE";
    }),
    isSold: Ember.computed("status", function () {
      return this.status === "SOLD";
    }),
    isDraft: Ember.computed("status", function () {
      return this.status === "DRAFT";
    }),
    isEditable: Ember.computed("status", function () {
      return this.status === "CHANGES_REQUESTED" || this.status === "DRAFT";
    }),
    isInAverageCondition: Ember.computed("condition", function () {
      return this.condition === "AVERAGE";
    }),
    isInGoodCondition: Ember.computed("condition", function () {
      return this.condition === "GOOD";
    }),
    isInExcellentCondition: Ember.computed("condition", function () {
      return this.condition === "EXCELLENT";
    }),
    allImages: Ember.computed("images", "featureImage", function () {
      return [this.featureImage].concat(_toConsumableArray(this.images));
    }),
    changePrice: function changePrice() {
      var adapter = this.store.adapterFor('product');
      return adapter.changePrice(this);
    }
  });

  _exports.default = _default;
});