define("gaff-interiors-web/templates/sign-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "stJZ9zEk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-6\"],[8],[0,\"\\n  \"],[5,\"sign-up-form\",[],[[\"@account\",\"@onSubmit\",\"@afterSubmit\"],[[23,0,[\"model\"]],[28,\"perform\",[[23,0,[\"signUp\"]]],null],[28,\"perform\",[[23,0,[\"afterSignUp\"]]],null]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/sign-up.hbs"
    }
  });

  _exports.default = _default;
});