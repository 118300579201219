define("gaff-interiors-web/models/reset-password", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    username: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Email can't be empty"
    }), (0, _emberCpValidations.validator)("format", {
      type: "email",
      message: "Not a valid email address"
    }), (0, _emberCpValidations.validator)("ds-error")],
    newPassword: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Password can't be empty"
    }), (0, _emberCpValidations.validator)("format", {
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      message: "Password must include at least one upper case letter, one lower case letter, and a number"
    })],
    newPasswordConfirmed: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Please confirm your password"
    }), (0, _emberCpValidations.validator)("confirmation", {
      on: "newPassword",
      message: "Passwords do not match"
    })]
  });

  var _default = Model.extend(Validations, {
    username: attr("string"),
    confirmationCode: attr("string"),
    newPassword: attr("string"),
    newPasswordConfirmed: attr("string"),
    lastSaveFailed: Ember.computed("isError", "isValid", function () {
      return this.isError || !this.isValid;
    })
  });

  _exports.default = _default;
});