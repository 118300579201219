define("gaff-interiors-web/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jdKUEzZ8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-6\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"sessionInvalidated\"]],\"yes\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"block text-center w-full mb-6 mt-4 text-sm text-orange\"],[8],[0,\" Your session has expired. Please login.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[5,\"login-form\",[],[[\"@onLoginSuccess\"],[[28,\"perform\",[[23,0,[\"onLoginSuccess\"]]],null]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/login.hbs"
    }
  });

  _exports.default = _default;
});