define("gaff-interiors-web/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oOAta5Uj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"w-full text-center mt-6 p-4\"],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"text-3xl md:text-5xl text-pink-gaff font-bold\"],[8],[0,\"This is awkward\"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"text-sm md:text-base mt-8 pb-2\"],[8],[0,\"The page you were looking for seems to have taken the day off and gone shopping.\"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"text-sm md:text-base mt-2 pb-2\"],[8],[0,\"Let's get you back to \"],[5,\"link-to\",[[12,\"class\",\"text-pink-gaff\"]],[[\"@route\"],[\"application\"]],{\"statements\":[[0,\"the shop's home page\"]],\"parameters\":[]}],[0,\".\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/404.hbs"
    }
  });

  _exports.default = _default;
});