define("gaff-interiors-web/router", ["exports", "gaff-interiors-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("sign-up");
    this.route("store");
    this.route("login");
    this.route("tutorials", function () {
      this.route("seller-intro");
    });
    this.route("session", function () {
      this.route("seller", function () {
        this.route("products", function () {
          this.route("product", {
            path: "/:product_id"
          }, function () {
            this.route("view", {
              path: "/"
            });
            this.route("edit", {
              path: "/edit"
            });
          });
          this.route("new");
          this.route("review-notice");
        });
        this.route("account");
      });
    });
    this.route("terms-and-conditions");
    this.route("store-front", function () {
      this.route("products", function () {
        this.route("product", {
          path: "/:product_id"
        });
      });
    });
    this.route("404", {
      path: "/*wildcard"
    });
    this.route('forgot-password');
    this.route('reset-password');
  });
  var _default = Router;
  _exports.default = _default;
});