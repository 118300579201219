define("gaff-interiors-web/app", ["exports", "gaff-interiors-web/resolver", "ember-load-initializers", "gaff-interiors-web/config/environment", "@sentry/browser", "@sentry/integrations/esm/ember"], function (_exports, _resolver, _emberLoadInitializers, _environment, Sentry, Integrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Sentry.init({
    dsn: "https://25fd2b82635549449e75c1b5e5ad107e@sentry.io/2076786",
    integrations: [new Integrations.Ember()],
    environment: _environment.default.APP.deployTarget,
    beforeSend: function beforeSend(event, hint) {
      var error = hint.originalException;

      if (error && (error.name === "TransitionAborted" || error.name === "FormValidationError")) {
        return null;
      }

      return event;
    }
  });
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});