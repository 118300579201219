define("gaff-interiors-web/templates/components/truncated-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5k6BULOM",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"p\",false],[13,1],[12,\"class\",\"truncated-text\"],[8],[1,[23,0,[\"text\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/components/truncated-text.hbs"
    }
  });

  _exports.default = _default;
});