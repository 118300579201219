define("gaff-interiors-web/templates/components/submit-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A7sHg1dr",
    "block": "{\"symbols\":[\"&attrs\",\"@submitTask\",\"@buttonType\",\"@title\"],\"statements\":[[7,\"button\",false],[13,1],[12,\"class\",[29,[\"focus: \",[23,0,[\"overrideStyles\"]],\" font-title rounded submit-button \",[28,\"if\",[[23,2,[\"isRunning\"]],\"animate\"],null],\" \",[28,\"if\",[[23,2,[\"last\",\"isSuccessful\"]],\"success\"],null],\" \",[28,\"if\",[[23,2,[\"last\",\"isError\"]],\"error\"],null]]]],[12,\"disabled\",[23,2,[\"isRunning\"]]],[12,\"type\",[23,3,[]]],[8],[0,\"\\n    \"],[1,[23,4,[]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/components/submit-button.hbs"
    }
  });

  _exports.default = _default;
});