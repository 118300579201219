define("gaff-interiors-web/adapters/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    session: Ember.inject.service(),
    headers: Ember.computed("session.data.authenticated.token", function () {
      var headers = {};

      if (this.session.isAuthenticated) {
        headers["Authorization"] = "".concat(this.session.data.authenticated.access_token);
      }

      return headers;
    }),
    handleResponse: function handleResponse(status) {
      if (401 === status) {
        this.session.invalidate();
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});