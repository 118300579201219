define("gaff-interiors-web/helpers/add", ["exports", "ember-math-helpers/helpers/add"], function (_exports, _add) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _add.default;
    }
  });
  Object.defineProperty(_exports, "add", {
    enumerable: true,
    get: function get() {
      return _add.add;
    }
  });
});