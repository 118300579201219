define("gaff-interiors-web/templates/session/seller/products/review-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WSmyLEff",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-6 flex justify-center text-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex flex-col w-full md:w-2/3 mt-4 p-1\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"font-title block text-xl text-grey-darkest font-bold\"],[8],[0,\"Your item has been submitted to GAFF Shop\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"block text-lg text-grey-darker mt-8\"],[8],[0,\"\\n      Thanks for submitting your item to GAFF Shop; it’s now awaiting approval with one of our team members. You can\\n      check on the item’s status \"],[5,\"link-to\",[],[[\"@route\"],[\"session.seller.products\"]],{\"statements\":[[0,\"here\"]],\"parameters\":[]}],[0,\".\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"block text-lg text-grey-darker mt-8\"],[8],[0,\"\\n      We’ll let you know if and when you’re live.\"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"text-grey-darker text-center text-md mt-8 text-lg leading-normal\"],[8],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[0,\"Seller Tip:\\n        \"],[9],[0,\"Some buyer's messages may end up in your junk, spam, or promotion folders. Don't forget to double-check your\\n        email to\\n        avoid missing out on any potential buyer requests!\"],[9],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"font-title w-14 no-underline text-center block bg-pink-gaff hover:bg-pink-gaff-light text-white font-bold text-lg py-4 px-8 rounded self-center mt-12\"]],[[\"@route\"],[\"session.seller.products\"]],{\"statements\":[[0,\"\\n      View my items\"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/session/seller/products/review-notice.hbs"
    }
  });

  _exports.default = _default;
});