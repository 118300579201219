define("gaff-interiors-web/models/registration", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Email can't be empty"
    }), (0, _emberCpValidations.validator)("format", {
      type: "email",
      message: "Not a valid email address"
    }), (0, _emberCpValidations.validator)("ds-error")],
    password: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Password can't be empty"
    }), (0, _emberCpValidations.validator)("format", {
      regex: /(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*([^\w\s]|[_]))\S{8,}/,
      message: "Password must be at least 8 characters in length and contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 symbol"
    })],
    passwordConfirmed: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Please confirm your password"
    }), (0, _emberCpValidations.validator)("confirmation", {
      on: "password",
      message: "Passwords do not match"
    })],
    firstName: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "First name can't be empty"
    })],
    lastName: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Last name can't be empty"
    })],
    termsAgreed: [(0, _emberCpValidations.validator)("inclusion", {
      allowBlank: false,
      in: [true],
      message: "You must agree to the terms and conditions"
    })]
  });

  var _default = Model.extend(Validations, {
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    password: attr('string'),
    passwordConfirmed: attr('string'),
    termsAgreed: attr('boolean', {
      defaultValue: false
    }),
    lastSaveFailed: Ember.computed('isError', 'isValid', function () {
      return this.isError || !this.isValid;
    })
  });

  _exports.default = _default;
});