define("gaff-interiors-web/templates/components/product-status-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CiiwAGY2",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"span\",false],[13,1],[12,\"class\",[29,[\"font-title text-white bg-\",[23,0,[\"labelColour\"]],\" text-center block font-bold md:text-lg py-1 px-2 md:py-2 md:px-4\"]]],[8],[1,[23,0,[\"status\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/components/product-status-label.hbs"
    }
  });

  _exports.default = _default;
});