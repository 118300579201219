define("gaff-interiors-web/models/conversation", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    buyerEmail: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Email can't be empty"
    }), (0, _emberCpValidations.validator)("format", {
      type: "email",
      message: "Not a valid email address"
    }), (0, _emberCpValidations.validator)("ds-error")],
    buyerName: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Name can't be empty"
    })],
    message: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "You must provide a message for the seller"
    })]
  });

  var _default = Model.extend(Validations, {
    buyerName: attr("string"),
    buyerEmail: attr("string"),
    message: attr("string"),
    productId: attr("string"),
    lastSaveFailed: Ember.computed("isError", "isValid", function () {
      return this.isError || !this.isValid;
    })
  });

  _exports.default = _default;
});