define("gaff-interiors-web/models/store-product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    name: attr("string"),
    createdAt: attr("date"),
    description: attr("string"),
    price: attr("number"),
    condition: attr("string"),
    location: attr("string"),
    pickupOnly: attr("boolean", {
      defaultValue: false
    }),
    status: attr("string"),
    images: attr("image-list"),
    featureImage: attr("image"),
    seo: attr(),
    descriptionCharCount: Ember.computed("description", function () {
      return this.description ? this.description.length : 0;
    }),
    descriptionMaxCharsReached: Ember.computed("description", function () {
      return this.description ? this.description.length === 500 : false;
    }),
    lastSaveFailed: Ember.computed("isError", "isValid", function () {
      return this.isError || !this.isValid;
    }),
    formattedCreatedAt: Ember.computed("createdAt", function () {
      return this.createdAt.toLocaleDateString();
    }),
    formatedCondition: Ember.computed("condition", function () {
      return "".concat(this.condition[0]).concat(this.condition.substring(1).toLowerCase());
    }),
    canBeSold: Ember.computed("status", function () {
      return this.status === "LIVE";
    }),
    isSold: Ember.computed("status", function () {
      return this.status === "SOLD";
    }),
    allImages: Ember.computed("images", "featureImage", function () {
      return [this.featureImage].concat(_toConsumableArray(this.images));
    })
  });

  _exports.default = _default;
});