define("gaff-interiors-web/helpers/in-arr", ["exports", "ember-inline-edit/helpers/in-arr"], function (_exports, _inArr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _inArr.default;
    }
  });
  Object.defineProperty(_exports, "inArr", {
    enumerable: true,
    get: function get() {
      return _inArr.inArr;
    }
  });
});