define("gaff-interiors-web/templates/session/seller/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ok9zwIQx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-col text-grey-dark p-4 items-center\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"font-title text-xl font-semi-bold text-grey-darkest w-full text-center mt-4 mb-2\"],[8],[0,\"Account settings\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-4 md:p-8 w-full md:w-1/2 xl:w-2/5\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-lg mb-8\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"block font-bold mb-1 text-grey-darker\"],[8],[0,\"First name\"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"text-base text-grey-dark block\"],[8],[1,[23,0,[\"model\",\"firstName\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-lg mb-8\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"block font-bold mb-1 text-grey-darker\"],[8],[0,\"Last name\"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"text-base text-grey-dark block\"],[8],[1,[23,0,[\"model\",\"lastName\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-lg\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"block font-bold mb-1 text-grey-darker\"],[8],[0,\"Username\"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"text-base text-grey-dark block\"],[8],[1,[23,0,[\"model\",\"username\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"logout\"],null]],[10,\"class\",\"font-title mt-4 cursor-pointer no-underline text-center block bg-pink-gaff hover:bg-pink-gaff-light text-white font-black text-lg py-4 px-8 rounded self-center\"],[8],[0,\"Log\\n    out\"],[9],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"mt-8\"]],[[\"@route\"],[\"session.seller.products\"]],{\"statements\":[[0,\"Take me back to my items\"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/session/seller/account.hbs"
    }
  });

  _exports.default = _default;
});