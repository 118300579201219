define("gaff-interiors-web/templates/session/seller/products/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O2/2OSwh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-6 flex justify-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex flex-col w-full md:w-2/3 mt-4 p-1\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"font-title block text-xl text-grey-darkest font-semibold\"],[8],[0,\"Tell us about your item\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"mt-4 text-grey-darker\"],[8],[0,\"To ensure your item is approved by GAFF, please read our \"],[5,\"link-to\",[[12,\"target\",\"blank\"]],[[\"@route\"],[\"tutorials.seller-intro\"]],{\"statements\":[[0,\"seller's guide\"]],\"parameters\":[]}],[0,\" before getting started.\"],[9],[0,\"\\n    \"],[5,\"product-form\",[],[[\"@product\",\"@onSubmit\",\"@afterSubmit\",\"@onSaveDraft\",\"@afterSaveDraft\"],[[23,0,[\"model\"]],[28,\"perform\",[[23,0,[\"submitProduct\"]]],null],[28,\"perform\",[[23,0,[\"productCreated\"]]],null],[28,\"perform\",[[23,0,[\"saveDraft\"]]],null],[28,\"perform\",[[23,0,[\"draftSaved\"]]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/session/seller/products/new.hbs"
    }
  });

  _exports.default = _default;
});