define("gaff-interiors-web/models/forgot-password", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    username: [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      ignoreBlank: true,
      message: "Email can't be empty"
    }), (0, _emberCpValidations.validator)("format", {
      type: "email",
      message: "Not a valid email address"
    }), (0, _emberCpValidations.validator)("ds-error")]
  });

  var _default = Model.extend(Validations, {
    username: attr("string")
  });

  _exports.default = _default;
});