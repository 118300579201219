define("gaff-interiors-web/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ldSx4z1G",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-6 h-full\"],[8],[0,\"\\n  \"],[5,\"reset-password\",[],[[\"@code\",\"@onResetSuccess\"],[[23,0,[\"code\"]],[28,\"perform\",[[23,0,[\"afterPasswordReset\"]]],null]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "gaff-interiors-web/templates/reset-password.hbs"
    }
  });

  _exports.default = _default;
});