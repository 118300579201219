define("gaff-interiors-web/tailwind/config/tailwind", ["exports", "tailwindcss/plugins/container", "gaff-interiors-web/tailwind/config/colors", "gaff-interiors-web/tailwind/config/screens", "gaff-interiors-web/tailwind/config/fonts", "gaff-interiors-web/tailwind/config/text-sizes", "gaff-interiors-web/tailwind/config/font-weights", "gaff-interiors-web/tailwind/config/line-height", "gaff-interiors-web/tailwind/config/letter-spacing", "gaff-interiors-web/tailwind/config/text-colors", "gaff-interiors-web/tailwind/config/background-colors", "gaff-interiors-web/tailwind/config/background-size", "gaff-interiors-web/tailwind/config/border-widths", "gaff-interiors-web/tailwind/config/border-colors", "gaff-interiors-web/tailwind/config/border-radius", "gaff-interiors-web/tailwind/config/width", "gaff-interiors-web/tailwind/config/height", "gaff-interiors-web/tailwind/config/min-width", "gaff-interiors-web/tailwind/config/min-height", "gaff-interiors-web/tailwind/config/max-width", "gaff-interiors-web/tailwind/config/max-height", "gaff-interiors-web/tailwind/config/padding", "gaff-interiors-web/tailwind/config/margin", "gaff-interiors-web/tailwind/config/negative-margin", "gaff-interiors-web/tailwind/config/shadows", "gaff-interiors-web/tailwind/config/z-index", "gaff-interiors-web/tailwind/config/opacity", "gaff-interiors-web/tailwind/config/svg-fill", "gaff-interiors-web/tailwind/config/svg-stroke", "glhd-tailwindcss-transitions"], function (_exports, _container, _colors, _screens, _fonts, _textSizes, _fontWeights, _lineHeight, _letterSpacing, _textColors, _backgroundColors, _backgroundSize, _borderWidths, _borderColors, _borderRadius, _width, _height, _minWidth, _minHeight, _maxWidth, _maxHeight, _padding, _margin, _negativeMargin, _shadows, _zIndex, _opacity, _svgFill, _svgStroke, _glhdTailwindcssTransitions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    colors: _colors.default,
    screens: _screens.default,
    fonts: _fonts.default,
    textSizes: _textSizes.default,
    fontWeights: _fontWeights.default,
    leading: _lineHeight.default,
    tracking: _letterSpacing.default,
    textColors: _textColors.default,
    backgroundColors: _backgroundColors.default,
    backgroundSize: _backgroundSize.default,
    borderWidths: _borderWidths.default,
    borderColors: _borderColors.default,
    borderRadius: _borderRadius.default,
    width: _width.default,
    height: _height.default,
    minWidth: _minWidth.default,
    minHeight: _minHeight.default,
    maxWidth: _maxWidth.default,
    maxHeight: _maxHeight.default,
    padding: _padding.default,
    margin: _margin.default,
    negativeMargin: _negativeMargin.default,
    shadows: _shadows.default,
    zIndex: _zIndex.default,
    opacity: _opacity.default,
    svgFill: _svgFill.default,
    svgStroke: _svgStroke.default,

    /*
    |-----------------------------------------------------------------------------
    | Modules                  https://tailwindcss.com/docs/configuration#modules
    |-----------------------------------------------------------------------------
    |
    | Here is where you control which modules are generated and what variants are
    | generated for each of those modules.
    |
    | Currently supported variants:
    |   - responsive
    |   - hover
    |   - focus
    |   - focus-within
    |   - active
    |   - group-hover
    |
    | To disable a module completely, use `false` instead of an array.
    |
    */
    modules: {
      appearance: ["responsive"],
      backgroundAttachment: ["responsive"],
      backgroundColors: ["responsive", "hover", "focus"],
      backgroundPosition: ["responsive"],
      backgroundRepeat: ["responsive"],
      backgroundSize: ["responsive"],
      borderCollapse: [],
      borderColors: ["responsive", "hover", "focus"],
      borderRadius: ["responsive"],
      borderStyle: ["responsive"],
      borderWidths: ["responsive"],
      cursor: ["responsive"],
      display: ["responsive"],
      flexbox: ["responsive"],
      float: ["responsive"],
      fonts: ["responsive"],
      fontWeights: ["responsive", "hover", "focus"],
      height: ["responsive"],
      leading: ["responsive"],
      lists: ["responsive"],
      margin: ["responsive"],
      maxHeight: ["responsive"],
      maxWidth: ["responsive"],
      minHeight: ["responsive"],
      minWidth: ["responsive"],
      negativeMargin: ["responsive"],
      objectFit: ["responsive", "hover", "focus", "active", "group-hover"],
      objectPosition: false,
      opacity: ["responsive"],
      outline: ["focus"],
      overflow: ["responsive"],
      padding: ["responsive"],
      pointerEvents: ["responsive"],
      position: ["responsive"],
      resize: ["responsive"],
      shadows: ["responsive", "hover", "focus"],
      svgFill: [],
      svgStroke: [],
      tableLayout: ["responsive"],
      textAlign: ["responsive"],
      textColors: ["responsive", "hover", "focus"],
      textSizes: ["responsive"],
      textStyle: ["responsive", "hover", "focus"],
      tracking: ["responsive"],
      userSelect: ["responsive"],
      verticalAlign: ["responsive"],
      visibility: ["responsive"],
      whitespace: ["responsive"],
      width: ["responsive"],
      zIndex: ["responsive"]
    },

    /*
    |-----------------------------------------------------------------------------
    | Plugins                                https://tailwindcss.com/docs/plugins
    |-----------------------------------------------------------------------------
    |
    | Here is where you can register any plugins you'd like to use in your
    | project. Tailwind's built-in `container` plugin is enabled by default to
    | give you a Bootstrap-style responsive container component out of the box.
    |
    | Be sure to view the complete plugin documentation to learn more about how
    | the plugin system works.
    |
    */
    plugins: [(0, _container.default)({// center: true,
      // padding: '1rem',
    }), (0, _glhdTailwindcssTransitions.default)()],

    /*
    |-----------------------------------------------------------------------------
    | Advanced Options         https://tailwindcss.com/docs/configuration#options
    |-----------------------------------------------------------------------------
    |
    | Here is where you can tweak advanced configuration options. We recommend
    | leaving these options alone unless you absolutely need to change them.
    |
    */
    options: {
      prefix: "",
      important: false,
      separator: ":"
    }
  };
  _exports.default = _default;
});